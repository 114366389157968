<template>
  <div>
    <b-row fluid class="mx-0 mb-2 mb-sm-3 justify-content-end">
      <b-button type="button" variant="primary" id="traRefresh" @click="getTraData()" class="mx-0">
        <Icon type="f" /> {{ $t('btn.f') }}
      </b-button>
    </b-row>
    <TransTable v-if="isUser" ref="table1" suffix="traTabCurU"
        :query="traTabDefU"
        showShow showReject showSubmit showPagination
        :noHitText="$t('tra.tno')" />
    <TransTable v-if="!isUser" ref="table2" suffix="traTabCurC"
        :query="traTabDefC" :noHitText="$t('tra.tno')"
        showShow showCancel showPagination />
  </div>
</template>

<script>
import Icon from '@/components/Icon';
import TransTable from '@/components/transactions/TransTable';

export default {
  data() {
    return {
      traTabDefU: { q: 'createdAt:ge:{T-1},createdAt:le:{N},senderUuid4::{U}', s: '+createdAt', p: '10' },
      traTabDefC: { q: 'createdAt:ge:{T},createdAt:le:{N},senderUuid4:n:{B},receiverUuid4::{U}', s: '+createdAt', p: '20' },
    }
  },
  computed: {
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
  },
  methods: {
    getTraData() {
      if (this.isUser) {
        this.$refs['table1'].getTraData();
      } else {
        this.$refs['table2'].getTraData();
      }
    },
  },
  components: {
    TransTable,
    Icon,
  },
}
</script>
